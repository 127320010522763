<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
        <!---->
      </div>
      <div class="cate">
        <span class="selected">龙虎</span>
        <span>龙虎B</span>
      </div>
      <div class="player listmain type-ssc xjsssc game-xjsssc game-ssc-lh">
        <!-- 龙 -->
        <div class="data clh_l">
          <h3>龙</h3>
          <ul>
            <li balls="lh_l" itid="58583" code="lh|Z1-Z2" id="lh|Z1-Z2_58583">
              <ol class="td_name">
                1-2
              </ol>
              <ol id="rate_58583" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58583" id="cash_58583" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58586" code="lh|Z1-Z3" id="lh|Z1-Z3_58586">
              <ol class="td_name">
                1-3
              </ol>
              <ol id="rate_58586" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58586" id="cash_58586" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58589" code="lh|Z1-Z4" id="lh|Z1-Z4_58589">
              <ol class="td_name">
                1-4
              </ol>
              <ol id="rate_58589" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58589" id="cash_58589" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58592" code="lh|Z1-Z5" id="lh|Z1-Z5_58592">
              <ol class="td_name">
                1-5
              </ol>
              <ol id="rate_58592" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58592" id="cash_58592" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58595" code="lh|Z2-Z3" id="lh|Z2-Z3_58595">
              <ol class="td_name">
                2-3
              </ol>
              <ol id="rate_58595" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58595" id="cash_58595" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58598" code="lh|Z2-Z4" id="lh|Z2-Z4_58598">
              <ol class="td_name">
                2-4
              </ol>
              <ol id="rate_58598" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58598" id="cash_58598" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58601" code="lh|Z2-Z5" id="lh|Z2-Z5_58601">
              <ol class="td_name">
                2-5
              </ol>
              <ol id="rate_58601" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58601" id="cash_58601" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58604" code="lh|Z3-Z4" id="lh|Z3-Z4_58604">
              <ol class="td_name">
                3-4
              </ol>
              <ol id="rate_58604" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58604" id="cash_58604" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58607" code="lh|Z3-Z5" id="lh|Z3-Z5_58607">
              <ol class="td_name">
                3-5
              </ol>
              <ol id="rate_58607" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58607" id="cash_58607" type="text" />
              </ol>
            </li>
            <li balls="lh_l" itid="58610" code="lh|Z4-Z5" id="lh|Z4-Z5_58610">
              <ol class="td_name">
                4-5
              </ol>
              <ol id="rate_58610" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58610" id="cash_58610" type="text" />
              </ol>
            </li>
          </ul>
        </div>
        <!-- 虎 -->
        <div class="data clh_h">
          <h3>虎</h3>
          <ul>
            <li balls="lh_h" itid="58584" code="lh|Z1-Z2" id="lh|Z1-Z2_58584">
              <ol id="rate_58584" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58584" id="cash_58584" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58587" code="lh|Z1-Z3" id="lh|Z1-Z3_58587">
              <ol id="rate_58587" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58587" id="cash_58587" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58590" code="lh|Z1-Z4" id="lh|Z1-Z4_58590">
              <ol id="rate_58590" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58590" id="cash_58590" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58593" code="lh|Z1-Z5" id="lh|Z1-Z5_58593">
              <ol id="rate_58593" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58593" id="cash_58593" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58596" code="lh|Z2-Z3" id="lh|Z2-Z3_58596">
              <ol id="rate_58596" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58596" id="cash_58596" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58599" code="lh|Z2-Z4" id="lh|Z2-Z4_58599">
              <ol id="rate_58599" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58599" id="cash_58599" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58602" code="lh|Z2-Z5" id="lh|Z2-Z5_58602">
              <ol id="rate_58602" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58602" id="cash_58602" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58605" code="lh|Z3-Z4" id="lh|Z3-Z4_58605">
              <ol id="rate_58605" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58605" id="cash_58605" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58608" code="lh|Z3-Z5" id="lh|Z3-Z5_58608">
              <ol id="rate_58608" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58608" id="cash_58608" type="text" />
              </ol>
            </li>
            <li balls="lh_h" itid="58611" code="lh|Z4-Z5" id="lh|Z4-Z5_58611">
              <ol id="rate_58611" class="td_rate">
                1.9898
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58611" id="cash_58611" type="text" />
              </ol>
            </li>
          </ul>
        </div>
        <!-- 和 -->
        <div class="data clh_d">
          <h3>和</h3>
          <ul>
            <li balls="lh_d" itid="58585" code="lh|Z1-Z2" id="lh|Z1-Z2_58585">
              <ol id="rate_58585" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58585" id="cash_58585" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58588" code="lh|Z1-Z3" id="lh|Z1-Z3_58588">
              <ol id="rate_58588" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58588" id="cash_58588" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58591" code="lh|Z1-Z4" id="lh|Z1-Z4_58591">
              <ol id="rate_58591" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58591" id="cash_58591" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58594" code="lh|Z1-Z5" id="lh|Z1-Z5_58594">
              <ol id="rate_58594" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58594" id="cash_58594" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58597" code="lh|Z2-Z3" id="lh|Z2-Z3_58597">
              <ol id="rate_58597" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58597" id="cash_58597" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58600" code="lh|Z2-Z4" id="lh|Z2-Z4_58600">
              <ol id="rate_58600" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58600" id="cash_58600" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58603" code="lh|Z2-Z5" id="lh|Z2-Z5_58603">
              <ol id="rate_58603" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58603" id="cash_58603" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58606" code="lh|Z3-Z4" id="lh|Z3-Z4_58606">
              <ol id="rate_58606" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58606" id="cash_58606" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58609" code="lh|Z3-Z5" id="lh|Z3-Z5_58609">
              <ol id="rate_58609" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58609" id="cash_58609" type="text" />
              </ol>
            </li>
            <li balls="lh_d" itid="58612" code="lh|Z4-Z5" id="lh|Z4-Z5_58612">
              <ol id="rate_58612" class="td_rate">
                8.88
              </ol>
              <ol class="td_cash">
                <input once_max="0" name="cash_58612" id="cash_58612" type="text" />
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe :disabled="isDisabled" />
      <!-- 下面五球 表格 -->
      <div class="roadmap">
        <!-- 表头 -->
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th data-code="lh_b1-b2" name="lh_b1-b2" class="">1-2</th>
              <th data-code="lh_b1-b3" name="lh_b1-b3" class="">1-3</th>
              <th data-code="lh_b1-b4" name="lh_b1-b4" class="">1-4</th>
              <th data-code="lh_b1-b5" name="lh_b1-b5" class="selected">1-5</th>
              <th data-code="lh_b2-b3" name="lh_b2-b3">2-3</th>
              <th data-code="lh_b2-b4" name="lh_b2-b4">2-4</th>
              <th data-code="lh_b2-b5" name="lh_b2-b5">2-5</th>
              <th data-code="lh_b3-b4" name="lh_b3-b4">3-4</th>
              <th data-code="lh_b3-b5" name="lh_b3-b5">3-5</th>
              <th data-code="lh_b4-b5" name="lh_b4-b5">4-5</th>
            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
        <table colspan="0" id="lt_lh_b1-b2" cellspan="0" class="lttab" style="display: table">
          <tbody>
            <tr>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_d">和</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_d">和</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_d">和</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_d">和</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_d">和</td>
              <td class="lh|b1-b2_h">虎</td>
            </tr>
          </tbody>
        </table>
        <!-- 表格内容 -->
      </div>
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span>
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
    };
  },
  components: {
    yushe,
    topTimer,
  },
  watch: {
    amount(val) {
      this.rowsData.forEach((item) => {
        if (item.selected) {
          item.cash = val;
        }
      });
      this.$forceUpdate();
    },
  },
  created() {},
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.cash) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    // input聚焦 判断显示快速下注
    showOrHideCheck(e, obj) {
      console.log(123, this.quick_b && this.quick_c);
      if (this.quick_b && this.quick_c) {
        this.rowsData.forEach((item) => {
          item.forEach((ele) => {
            if (ele.index == obj.index) {
              ele.b = true;
            } else {
              ele.b = false;
            }
          });
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style scoped>
.listmain .data ul li {
  width: 100%;
}
.listmain > .data > ul:last-child {
  border-bottom: none;
}
.listmain > .data > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.listmain .data h3 {
  border: solid 1px #b9c2cb;
  border-bottom: none;
  border-right: none;
}
.listmain > .data > h3 {
  height: 24px;
  line-height: 24px;
  text-align: center;
  /* background: #e0e0e0; */
  font-weight: 700;
  font-size: 13px;
}
.listmain.game-ssc-kd .data,
.listmain.game-ssc-lh .data,
.listmain.game-ssc-qt .data {
  margin-right: 2px;
  width: calc((100% - 6px) / 3);
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -ms-flex-flow: row wrap; */
  /* flex-flow: row wrap; */
  width: 100%;
  clear: both;
}
.listmain > .data {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -ms-flex-flow: row wrap;
  flex-flow: row wrap; */
  width: 100%;
  clear: both;
}
/* 头部 dohand 的样式 */
.container .main .dohand .btn2 {
  position: absolute;
  top: 10px;
  width: 62px;
  height: 18px;
  line-height: 18px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ff925f),
    to(#ff7340)
  );
  background: linear-gradient(180deg, #ff925f, #ff7340);
  color: #fff;
  border: 1px solid #ff6835;
  cursor: pointer;
  text-align: center;
}
.container .main .dohand .btn2.a {
  right: 0;
}
.skin-brown .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #b99770),
    to(#dfb55e)
  );
  background: linear-gradient(180deg, #b99770, #dfb55e);
  border: 1px solid #dfb55e;
}
.skin-red .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #d87c86),
    to(#6a1f2d)
  );
  background: linear-gradient(180deg, #d87c86, #6a1f2d);
  border: 1px solid #ab374a;
}
.skin-blue .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #5b8ac7),
    to(#2765b5)
  );
  background: linear-gradient(180deg, #5b8ac7, #2765b5);
  border: 1px solid #1e57a0;
}

.container .main .dohand .btn.none {
  background: #999;
  border: 1px solid #999;
}

.container .main .dohand .btn {
  margin-left: 15px;
  width: 58px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.container .main .dohand .amount input {
  margin-left: 5px;
  border: 1px solid #b0b0b0;
  height: 18px;
  padding: 0 2px;
  width: 65px;
  background: url("../../assets/input.gif") no-repeat 0 0;
  color: #333;
  font-weight: 400;
}
.container .main .dohand .amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  font-weight: 700;
}
.container .main .dohand .preset.selected:after {
  position: absolute;
  content: "";
  left: 3px;
  top: 4px;
  width: 10px;
  height: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.container .main .dohand .preset:before {
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  border: 1px solid #bbb;
  height: 14px;
  width: 14px;
}
.container .main .dohand .preset {
  position: relative;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  padding-left: 20px;
  cursor: pointer;
}
.skin-brown .bodyer .container .main .dohand {
  color: #dfb55e;
}
.skin-red .bodyer .container .main .dohand {
  color: #6a1f2d;
}
.skin-blue .bodyer .container .main .dohand {
  color: #38539a;
}
.container .main .dohand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 10px 0;
  background: #fff;
  line-height: 20px;
}
</style>
